.programs h1 {
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  /* background-color: var(--color-bg); */
}

/* h2 {
  color: #ff6d4d;
  font-family: var(--font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.503px;
  padding: 2rem 0;
} */

.programs p {
  max-width: 900px;
  font-family: var(--font-family);
}

.programs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 3rem;
}

/* @media screen and (max-width: 950px) {
}

@media screen and (max-width: 750px) {
  .about-church p {
    margin: 0rem 0rem;
  }
  .about-church {
    padding: 0rem 2rem;
  }
  .about-church img {
    width: 500px;
    padding: 1rem;
  }
} */
