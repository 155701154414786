.amst__header {
  display: flex;
  background: var(--color-bg);
  justify-content: center;
  padding: 0rem 6rem;
}

.amst__header-text {
  justify-content: space-around;
  flex: 1;
  margin-right: 1rem;
  display: flex;
  width: 45%;
  flex-direction: column;
}

.amst__header-picture {
  width: 45%;
  float: right;
  margin-left: 1rem;
}

.amst__header-picture img {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
}

.amst__header-text h1 {
  color: #ff6d4d;
  font-family: var(--font-family);
  font-size: 9.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 88%;
  letter-spacing: -1.503px;
}

.amst__header-text h2 {
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 32.314px */
}

.amst__header-text button {
  width: 121.75px;
  height: 50.745px;
  border-radius: 9.247px;
  background: #3384e2;
  outline: none;
  border: none;

  color: var(--color-bg);
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  transition: 0.3s;
  color: #fff;
}

.amst__header-text button:hover {
  background: #15539a;
}

@media screen and (max-width: 700px) {
  .amst__header {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .amst__header {
    padding: 2rem;
  }

  .amst__header {
    flex-direction: column;
  }

  .amst__header-text,
  .amst__header-picture {
    width: 100%;
    flex: 1;
    margin: 0 0;
  }

  .amst__header-text h1 {
    font-size: 21vw;
  }

  .amst__header-text h2 {
    font-size: 4.5vw;
  }

  .amst__header-text button {
    font-size: 3.5vw;
    height: 50px;
    width: 25%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}
