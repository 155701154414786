.amst__navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 6rem;
  background: var(--color-bg);
}

.amst__navbar-links {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.amst__navbar-links_container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.amst__navbar-sign button,
.amst__navbar-menu_container button {
  width: 121.75px;
  height: 50.745px;
  border-radius: 9.247px;

  background: #3384e2;
  outline: none;
  border: none;

  color: var(--color-bg);
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  transition: 0.3s;

  line-height: normal;
  cursor: pointer;
}

.amst__navbar-sign button:hover,
.amst__navbar-menu_container button:hover {
  background: #2563a9;

  color: white;
  transition: 0.5s;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.amst__navbar-links p,
.amst__navbar-sign p,
.amst__navbar-menu_container p,
.amst__navbar-menu_container .navbar-link,
.amst__navbar-links .navbar-link,
.amst__navbar-sign .navbar-link,
.amst__navbar-menu .navbar-link {
  margin: 2.5rem 1rem;
  padding: 1rem 0rem;

  font-family: var(--font-family);

  color: #59564d;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  display: inline-block;
}

.amst__navbar-menu_container-links-sign p,
.amst__navbar-menu_container button {
  color: var(--color-bg);
}

.amst__navbar-sign {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.amst__navbar-links_logo {
  margin-right: 2rem;
}

.nav a.navbar-link:hover,
.nav .active,
.amst__navbar-sign a.navbar-link:hover,
.amst__navbar-sign .active,
.amst__navbar-menu .navbar-link:hover {
  text-decoration: underline !important;
  text-decoration-color: #3384e2 !important;
  /* text-decoration-style: solid !important; */
  text-decoration-thickness: 4px !important;
  text-underline-offset: 8px !important;
  /* transition: 0.3s; */
  /* transform: scale(1.1) translateZ(1px); */
  color: rgba(0, 0, 0, 0.85);
}

/* .amst__navbar-links_logo img{ */

.amst__navbar-links_logo img {
  width: 86.667px;
  height: 76.19px;
  opacity: 65%;
  cursor: pointer;
  transition: 0.6s;
}

.amst__navbar-links_logo img:hover {
  opacity: 0.8;
  transition: 0.6s;
}

.amst__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.amst__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  padding: 2rem;

  background: #ff6d4d;
  position: absolute;
  top: 60px;
  right: 0;
  /* margin-top: 1rem; */
  min-width: 230px;
  border-radius: 5px;
  /* box-shadow:  5px 5px 9px rgba(0,0,0,0.2); */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* width:100%; */

  visibility: visible;
  z-index: 1;
}

.amst__navbar-menu_container-links-sign {
  /* opacity:1; */
  display: none;
}

@media screen and (max-width: 1050px) {
  .amst__navbar-links_container {
    display: none;
  }

  .amst__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .amst__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .amst__navbar {
    padding: 2rem;
  }

  .amst__navbar-sign {
    display: none;
  }

  .amst__navbar-menu_container {
    /* top:20px; */
  }

  .amst__navbar-menu_container-links-sign {
    display: block;
    opacity: 1;
  }
}
