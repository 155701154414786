input {
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background-color: var(--color-bg);
  /* width:280px; */
  outline: none;
}

input:focus {
  /* outline: 3px solid #3384E2; */
}

/* label {
    font-family: var(--font-family);
    font-size: 12px;
    color:black;
    opacity: 0.6;
} */

.formInput {
  display: flex;
  flex-direction: column;
}

/* span {
    font-size: 12px;
    font-family: var(--font-family);
    color: rgb(68, 0, 255);
    color: #000;
    color: var(--color-bg);

    padding: 3px;
    display: none;
} */

input:invalid[focussed="true"] ~ span {
  display: block;
}

input:invalid[focussed="true"] {
  outline: 2px solid rgba(255, 255, 255);
  background: #ff7070;
  color: white;
}

input:invalid[focussed="true"]::placeholder {
  color: white;
}
