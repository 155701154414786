.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account h1 {
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  /* background-color: var(--color-bg); */
}

.account h2 {
  color: rgba(0, 0, 0, 0.55);
  color: #ff6d4d;
  font-family: var(--font-family);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 4rem 0rem;
  /* background-color: var(--color-bg); */
}

.account button {
  width: 121.75px;
  height: 50.745px;
  border-radius: 9.247px;
  /* border-radius: 15%; */
  background: #3384e2;
  outline: none;
  border: none;

  color: var(--color-bg);
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  transition: 0.3s;
  /* font-weight: */
  margin: 4rem 0rem;

  line-height: normal;
  cursor: pointer;
}

.account button:hover {
  background: #2563a9;

  color: white;
  transition: 0.5s;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.times ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.55);
}

.times h2 {
  margin-top: 4rem;
  padding: 0;
}
