* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  /* max-width: 1440px; */
}

a {
  color: unset;
  text-decoration: none;
}

.main-page {
  /* max-height: 700px; */
  max-width: 1540px;
  margin: auto;
  /* padding: 80px; */
  /* color: aqua; */
  /* background-color: aqua; */
  /* justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center; */
}
