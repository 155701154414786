.about h1 {
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

h2 {
  color: #ff6d4d;
  font-family: var(--font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.503px;
  padding: 2rem 0;
}

.about-church p {
  max-width: 900px;
  font-family: var(--font-family);
}

.about-church {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 3rem;
}

.about-church img {
  margin: 2rem 0;
  width: 711px;
  /* height: 404px; */
  border-radius: 1rem;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 750px) {
  .about-church p {
    margin: 0rem 0rem;
  }
  .about-church {
    padding: 0rem 2rem;
  }
  .about-church img {
    width: 500px;
    margin: 1rem;
    border-radius: 1rem;
  }
}
