.amst__booking {
  display: flex;
  padding: 1rem 6rem;
  background: var(--color-bg);
  flex-direction: column;
}

.amst__booking-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.amst__booking .amst__booking-calendar,
.amst__booking .buttons-container {
  margin: 1rem;
}

.amst__booking-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px;
}

.buttons-container {
  width: 350px;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amst__booking-buttons {
  display: grid;
  grid-template-areas:
    "button1 button2 button3"
    "button4 button5 button6"
    "button7 button8 button9"
    "button10 button11 button12"
    "button13 button14 button15";

  min-height: 300px;
  max-height: 300px;
  max-width: 300px;
  grid-gap: 1rem;
}

.amst__booking-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amst__booking-title p {
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.amst__booking-title p {
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.amst__booking-title h1 {
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-color: var(--color-bg);
  text-align: center;
}

.amst__booking .checkout {
  display: flex;
  width: 350px;
  justify-content: center;
  margin: 1rem;
}

.amst__booking-bottom-info {
  display: flex;
  justify-content: center;
}

.amst__booking .checkout button {
  margin: 0rem 0.5rem;
  background: #3384e2;
  border: none;
  color: var(--color-bg);

  min-width: 120px;
  font-size: 18px;
  font-family: var(--font-family);
  font-weight: 500;
  border-radius: 8px;

  width: 121.75px;
  height: 50.745px;
  transition: 0.5s;

  line-height: 18px;
}

.amst__booking .timeOutput p {
  font-size: 15px;
  font-family: var(--font-family);
}

.timeOutput {
  font-family: var(--font-family);
  width: 350px;
  margin: 1rem;
}

.timeOutput ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 2rem;
}

.timeOutput h1 {
  font-size: 28px;
}

.timeOutput p {
  margin: 0;
  padding: 0;
  font-size: 25px;
}

.checkout button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.checkout button:hover:enabled {
  background: #2563a9;
  transition: 0.5s;
}

@media screen and (max-width: 750px) {
  .amst__booking-container {
    flex-direction: column;
  }

  .amst__booking {
    padding: 2rem 2rem;
  }

  .amst__booking-calendar {
  }

  .amst__booking-bottom-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
