.timeButton {
  border-radius: 8px;
  background: none;
  outline: none;
  color: #ff6d4d;
  border: 2px solid #ff6d4d;
  font-family: var(--font-family);
  font-size: 13.868px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: 0.5s;
}

.free:hover {
  color: var(--color-bg);
  background: #ff6d4d;
  transition: 0.5s;
}

.selected {
  color: var(--color-bg);
  background: #ff6d4d;
}

.selected:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.selected:active {
  background: none;
  color: #ff6d4d;
  transition: 0.5s;
}

.blocked {
  cursor: not-allowed;
  opacity: 0.4;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
}
